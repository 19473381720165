.hoverClick {
  cursor: default;
}

.hoverClick:hover {
  cursor: pointer;
}

.imageResize {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}